import React from 'react'
import styled from 'styled-components'
// eslint-disable-next-line no-unused-vars
import { graphql } from 'gatsby'
import PageLayout from '../../layouts/PageLayout/PageLayout'
import {
	AddedHP,
	AddedNM,
	BlockRecentlyTunedSection,
	Car,
	CarBox,
	CarData,
	CarImage,
	CarsContainer,
	CarSpecifications,
	HP,
	Model,
	Name,
	NM,
	OriHP,
	OriNM
} from '../../components/ACF/BlockRecentlyTuned/BlockRecentlyTuned.styled'
import { getImage } from 'gatsby-plugin-image'
import BlockTitle from '../../components/ACF/BlockTitle/BlockTitle'
import BlockCallToAction from '../../components/ACF/BlockCallToAction/BlockCallToAction'
import ButtonComponent from '../../components/Button'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Icon from '../../components/Icon/Icon'

const CarNotFound = ({ data, state, location }) => {
	const carName = `${data.product.brand.name} ${data.product.model.name} ${data.product.name}`

	const brandData = {
		seo: {
			canonical: `/chiptunen/${carName}`,
			cornerstone: false,
			focuskw: `chiptunen ${carName}`,
			metaDesc: `Het chiptunen van jouw ${carName} geeft jouw auto meer pit en souplesse. Naast het extra vermogen wat jouw ${carName} krijgt kan chiptuning een aanzienlijke brandstofbesparing opleveren. Een besparing van wel 5 tot 10% is mogelijk na het chiptunen van jouw ${carName}.`,
			metaKeywords: `chiptunen ${carName} - ECU adaptions`,
			metaRobotsNofollow: 'follow',
			metaRobotsNoindex: 'index',
			opengraphTitle: `Chiptunen ${carName} - ECU adaptions`,
			title: `Chiptunen ${carName} - ECU adaptions`
		},
		header: {
			backgroundColor: '#000000',
			backgroundOpacity: 0.2,
			fieldGroupName: 'header',
			height: 50,
			heroChoice: 'image',
			heroText: data?.brand?.name,
			isFrontPage: false,
			image: data?.brand?.image,
			pxOrVh: 'vh',
			videoUrl: null
		},
		...data?.brand
	}

	return (
		<PageLayout pageData={brandData}>
			<BrandPageContentWrapper padding="80px 0 0 0">
				{/* <Title>{brandData.name}</Title> */}
				<Title>{carName}</Title>
				<ContentContainer>
					<TextContainer>
						{' '}
						{/* <TextTitle>Chiptunen {brandData.name}</TextTitle> */}
						<TextTitle>Chiptunen {carName}</TextTitle>
						<Text>
							{`Momenteel is het chiptunen van jouw ${carName} nog niet mogelijk bij ECU Adaptions of is nog in ontwikkeling.`}
						</Text>
						<Notice>
							{`Let op; Het kan zijn dat uw auto wel beschikbaar is voor chiptunen. Neem daarom altijd even contact met ons op of vraag een offerte aan.`}
						</Notice>
					</TextContainer>
					<FeaturesContainer>
						<TextTitle>
							Dit zijn de voordelen van het op maat chiptunen van jouw {carName} door ECU Adaptions:
						</TextTitle>
						<FeatureItem>
							<Icon style={{}} width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Meer vermogen
						</FeatureItem>
						<FeatureItem>
							<Icon style={{}} width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Meer trekkracht
						</FeatureItem>
						<FeatureItem>
							<Icon style={{}} width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Minder brandstofverbruik
						</FeatureItem>
						<FeatureItem>
							<Icon style={{}} width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Meer rijplezier en comfort
						</FeatureItem>
					</FeaturesContainer>
				</ContentContainer>
				<ButtonContainer>
					<ButtonComponent variant="primary" url="/offerte-aanvragen" text="Vraag offerte aan" />
					&nbsp;
					<ButtonComponent variant="primary" url="/contact" text="Neem contact op" />
				</ButtonContainer>
			</BrandPageContentWrapper>
		</PageLayout>
	)
}

const BrandPageContentWrapper = styled.section`
	padding: ${props => props.padding};
`

const ButtonContainer = styled.div`
	margin-bottom: 3rem;
`

const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		flex-direction: row;
		align-items: center;
	}
`

const TextContainer = styled.div`
	flex: 3;
	margin-right: 3rem;
	margin-bottom: 3rem;
`

const FeaturesContainer = styled.div`
	flex: 2;
	margin-bottom: 1.5rem;
`

const Title = styled.h1`
	margin: 0;
	margin-bottom: 3rem;
`

const TextTitle = styled.h5`
	margin: 0;
	margin-bottom: 1rem;
	max-width: 600px;
`

const Text = styled.p`
	margin: 0;
	margin-bottom: 1.5rem;
`

const Notice = styled.p`
	margin: 0;
	font-style: italic;
	color: rgb(129, 129, 129);
`

const FeatureItem = styled.p`
	display: flex;
	flex-direction: row;
	margin: 0;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	align-items: center;
`

export default CarNotFound

export const query = graphql`
	query NotFoundQuery($productId: String!, $brandId: String!) {
		brand(_id: { eq: $brandId }) {
			image {
				localFile {
					childImageSharp {
						gatsbyImageData(width: 1920)
					}
				}
			}
		}
		product(id: { eq: $productId }) {
			id
			name
			details {
				pkBefore
				pkAfter
				nmBefore
				nmAfter
				fuelType
				cylinderCapacity
				compressionRatio
				ecuType
				boringX
				motorNumber
			}
			brand {
				name
				_id
			}
			model {
				name
				_id
			}
			generation {
				name
				_id
			}
			slug
		}
	}
`
